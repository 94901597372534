/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import { FaUser } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import MentorList from './MentorList';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const NewWebinarScreen = () => {
  const store = useContext(MainStore);
  const history = useHistory();

  const [date, setDate] = useState();
  const [title, settitle] = useState('');
  const [industry, setindustry] = useState('');
  const [from, setfrom] = useState('8:00');
  const [to, setto] = useState('9:00');
  const [seats, setseats] = useState('');
  const [desc, setdesc] = useState('');
  const [mentor, setmentor] = useState('');
  const [details, setdetails] = useState('');

  const [show, setshow] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Box p="12px">
      <HStack>
        <Button onClick={() => history.goBack()}>Back</Button>
      </HStack>

      <Box my="12px">
        <Text fontSize="24px" fontWeight="bold">
          Create Webinar
        </Text>
      </Box>
      <Panel>
        <Box p="12px">
          <HStack spacing="12px">
            <Text whiteSpace="nowrap">Select Webinar Date</Text>
            <FormControl isRequired>
              <Flatpickr
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  dateFormat: 'Y-m-d',
                }}
                value={date}
                onChange={d => {
                  if (store.debugMode) console.log(d[0]);
                  setDate(d[0]);
                }}
                render={({ defaultValue, value, ...props }, ref) => {
                  return (
                    <Input
                      isRequired
                      placeholder="Select Date"
                      {...props}
                      ref={ref}
                    />
                  );
                }}
              />
            </FormControl>
          </HStack>
        </Box>
        <Divider />
        <form
          onSubmit={e => {
            e.preventDefault();
            const payload = {
              mentor_id: mentor.id,
              industry_id: parseInt(industry.value),
              date: date,
              title: title,
              time_from: from,
              time_to: to,
              seats: seats,
              short_desc: desc,
              description: details,
            };
            store._createWebinar(payload).then(res => {
              if (res) history.goBack();
            });
          }}
        >
          <Stack padding="12px" spacing="12px" display={date ? 'flex' : 'none'}>
            <FormControl isRequired>
              <Input
                placeholder="Event Title"
                value={title}
                onChange={e => settitle(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <Select
                placeholder="Select Industry"
                onChange={e => setindustry(e)}
                options={[
                  { value: '1', label: 'Corporate Finance/Investment' },
                  { value: '2', label: 'Banking/Financial' },
                  { value: '3', label: 'Clerical/Administrative' },
                  { value: '4', label: 'Secretarial' },
                  { value: '5', label: 'Business Planning' },
                  { value: '6', label: 'Blockchain' },
                  { value: '7', label: 'PR' },
                  { value: '8', label: 'StoryTelling' },
                  { value: '9', label: 'Business Development' },
                  { value: '10', label: 'Growth Hacking' },
                  { value: '11', label: 'Private Equity' },
                  { value: '12', label: 'Investor' },
                  { value: '13', label: 'Marketing' },
                  { value: '14', label: 'Product' },
                  { value: '15', label: 'Entreprenuer' },
                  { value: '16', label: 'Finance' },
                  { value: '17', label: 'Legal' },
                  { value: '18', label: 'Fund Raising' },
                  { value: '19', label: 'M&A' },
                  { value: '20', label: 'IoT' },
                  { value: '21', label: 'EdTech' },
                  { value: '22', label: 'Tech' },
                  { value: '23', label: 'Fintech' },
                  { value: '24', label: 'AI/ML' },
                  { value: '25', label: 'IP' },
                ]}
              />
            </FormControl>
            <SimpleGrid columns={2} spacing="12px">
              <FormControl isRequired>
                <FormLabel>Time From</FormLabel>
                <TimePicker
                  disableClock={true}
                  onChange={setfrom}
                  value={from}
                  required={true}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Time to</FormLabel>
                <TimePicker
                  disableClock={true}
                  onChange={setto}
                  value={to}
                  required={true}
                />
              </FormControl>
            </SimpleGrid>
            <FormControl isRequired>
              <Input
                placeholder="Seats"
                value={seats}
                onChange={e => setseats(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                placeholder="Short Description"
                value={desc}
                onChange={e => setdesc(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <Input
                contentEditable={false}
                cursor="pointer"
                placeholder="Mentor"
                value={mentor && `${mentor.first_name} ${mentor.last_name}`}
                onClick={() => setshow(true)}
              />
            </FormControl>

            <MentorList
              setMentor={setmentor}
              isOpen={show}
              onClose={() => setshow(false)}
            />

            <ReactQuill
              theme="snow"
              value={details}
              onChange={value => setdetails(value)}
            />
            <Button colorScheme="yellow" color="white" type="submit">
              Submit Webinar
            </Button>
          </Stack>
        </form>
      </Panel>
    </Box>
  );
};

export default NewWebinarScreen;
