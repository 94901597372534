/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import FeaturedInsights from './FeaturedInsights';
import FeaturedPitches from './FeaturedPitches';
import FeaturedCareers from './FeaturedCareers';
import FeaturedMembers from './FeaturedMembers';
import MentorSpotlights from './MentorSpotlights';
import NewWebinarScreen from './NewWebinarScreen';
import WebinarScreen from './WebinarScreen';
import PlayBookScreen from './PlaybookScreen';
import Dashboard from './Dashboard';
import UserProfile from './UserProfile';
import EditProfile from './EditProfile';
import RequestMentoring from './RequestMentoring';
import EditMySchedule from './EditMySchedule';
import MySchedules from './MySchedules';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';
import MyFeedbacks from './MyFeedbacks';

export default () => {
  let { path } = useRouteMatch();

  return (
    <Flex flexDir="column" h="100%" minH="100%">
      <Flex flex="1">
        <MenuBar />

        <Box flex={1} backgroundColor="#E4E9EA">
          <HeaderBar />
          <Switch>
            <Route exact path={path}>
              <Dashboard />
            </Route>
            <Route path={`${path}/schedules`}>
              <MySchedules />
            </Route>
            <Route path={`${path}/feedbacks`}>
              <MyFeedbacks />
            </Route>
            <Route path={`${path}/mentor-availability`}>
              <EditMySchedule />
            </Route>
            <Route path={`${path}/mentoring-form`}>
              <RequestMentoring />
            </Route>
            <Route path={`${path}/incoming-events`}>
              <WebinarScreen />
            </Route>
            <Route path={`${path}/new-webinar`}>
              <NewWebinarScreen />
            </Route>
            <Route path={`${path}/playbook`}>
              <PlayBookScreen />
            </Route>
            <Route path={`${path}/insights`}>
              <FeaturedInsights
                path={`${path}/insights`}
                title="Insights"
                subtitle="Get inspired, ask an expert on latest trends and insights from matter experts."
              />
            </Route>
            <Route path={`${path}/mentors`}>
              <MentorSpotlights />
              <FeaturedMembers
                path={`${path}/mentors`}
                title="Mentors"
                subtitle="Connect with matter experts from Silicon Valley and abroad."
                toGet="_getFeaturedMentors"
                type="mentor"
              />
            </Route>
            <Route path={`${path}/members`}>
              <FeaturedMembers
                path={`${path}/members`}
                title="Members"
                subtitle="Get to know your community."
                toGet="_getFeaturedMentees"
                type="mentee"
              />
            </Route>
            <Route path={`${path}/profile/edit`}>
              <EditProfile />
            </Route>
            <Route path={`${path}/profile/:userID`}>
              <UserProfile />
            </Route>
            <Route path={`${path}/pitches`}>
              <FeaturedPitches
                path={`${path}/pitches`}
                title="Member Pitches"
                subtitle="Pitching Competitions."
                toGet="_getPitches"
              />
            </Route>
            <Route path={`${path}/careers`}>
              <FeaturedCareers
                path={`${path}/careers`}
                title="Members Resumes"
                subtitle="Members video resumes for jobs."
              />
            </Route>
          </Switch>
        </Box>
      </Flex>
    </Flex>
  );
};

const MenuBar = observer(() => {
  const store = useContext(MainStore);

  let history = useHistory();
  let { path, url } = useRouteMatch();

  const [isOpen, setOpen] = useState(true);
  const breakPoint = useBreakpointValue({ base: 'base', lg: 'lg' });

  const menu = [{}];

  const onClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    //check history
  };

  useEffect(() => {
    breakPoint === 'base' ? setOpen(false) : setOpen(true);
    return () => {};
  }, [breakPoint]);

  return (
    <Box
      w={isOpen ? '230px' : '50px'}
      // w={{ base: '50px', lg: '230px' }}
      backgroundColor="#0e1111"
      // transition="width 0.25s"
      pos="relative"
      // onMouseEnter={onOpen}
      // onMouseLeave={onClose}
    >
      {isOpen ? (
        <Box p="12px">
          <Image maxW="200px" src={store.whitelabel.logo_path} />
        </Box>
      ) : (
        <Box h="50px" p="10px">
          <Image maxW="32px" src={LogoGlobe} />
        </Box>
      )}

      <IconButton
        _focus={{ outline: 'none' }}
        pos="absolute"
        top="16px"
        right="-44px"
        color="#fff"
        icon={<FiMenu />}
        variant="link"
        size="lg"
        onClick={() => setOpen(!isOpen)}
      />
      <Stack spacing="0px">
        <Flex
          p={isOpen ? '12px' : '6px'}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {store.userData && store.userData.user.profile_picture ? (
            <Image
              backgroundColor="grey"
              borderRadius="full"
              boxSize={isOpen ? '40px' : 'auto'}
              src={store.userData.user.profile_picture}
            />
          ) : (
            <Circle size="40px" bg="grey" color="black">
              <Icon as={FaUser} boxSize="18px" circle />
            </Circle>
          )}
          <Box color="white" display={isOpen ? 'block' : 'none'}>
            <Text fontWeight="bold">
              {store.userData && store.userData.user.full_name.substr(0, 15)}
            </Text>
            <Text fontSize="12px">{`${
              store.userData && store.userData.user.user_type.toUpperCase()
            }`}</Text>
            {/* <HStack>
              <Icon viewBox="0 0 200 200" color="green">
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text fontSize="12px">Online</Text>
            </HStack> */}
          </Box>
        </Flex>
        <MenuBarButton
          icon={FaColumns}
          label="My Dashboard"
          to={'/user'}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          icon={FaRegCalendarCheck}
          label="My Schedules"
          to={`${path}/schedules`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        {/* <MenuBarButton
          icon={FaColumns}
          label="My Feedbacks"
          to={`${path}/feedbacks`}
          onClick={onClose}
          isOpen={isOpen}
        /> */}
        <MenuBarButton
          // isDisabled={true}
          icon={FaStackExchange}
          label="Incoming Events"
          to={`${path}/incoming-events`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          icon={FaBook}
          label="Playbook"
          to={`${path}/playbook`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          icon={FaVideo}
          label="Insights"
          to={`${path}/insights`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          icon={FaIdBadge}
          label="Mentors"
          to={`${path}/mentors`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          icon={FaUserFriends}
          label="Members"
          to={`${path}/members`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          isDisabled={true}
          icon={FaRegStickyNote}
          label="Pitches"
          to={`${path}/pitches`}
          // onClick={onClose}
          isOpen={isOpen}
        />
        <MenuBarButton
          color="#b8c7ce"
          icon={FaRegListAlt}
          label="Careers"
          to={`${path}/careers`}
          // onClick={onClose}
          isOpen={isOpen}
        />

        <MenuBarButton
          color="#b8c7ce"
          icon={FaRegListAlt}
          label="Strategy and KPIs"
          target="_blank"
          onClick={() => {
            window.open('https://app.strategyexe.com/users/login');
          }}
          isOpen={isOpen}
        />
      </Stack>
      {isOpen && (
        <Box p="12px">
          <Image
            maxW="200px"
            src={store.whitelabel.logo_path}
            alt={store.whitelabel.title}
            // fallbackSrc="https://via.placeholder.com/150"
          />
        </Box>
      )}
    </Box>
  );
});

const MenuBarButton = (
  { icon, to, label, isOpen, onClick, isDisabled, isActive },
  props
) => {
  return (
    <HStack
      backgroundColor={isActive ? 'rgba(255,255,255,0.25)' : 'none'}
      color={isActive ? 'white' : '#b8c7ce'}
      borderLeft={isActive ? '2px solid #f39c12' : 'none'}
      pointerEvents={isDisabled ? 'none' : 'initial'}
      opacity={isDisabled ? 0.25 : 1}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      {...props}
      px="14px"
      py="12px"
      _hover={{
        backgroundColor: 'rgba(255,255,255,0.25)',
        transition: 'background-color 0.25s',
        color: '#fff',
      }}
      as={Link}
      to={to}
      onClick={onClick}
    >
      <Icon as={icon} color="#b8c7ce" boxSize="16px" />
      <Text
        display={isOpen ? 'block' : 'none'}
        fontSize="1rem"
        fontFamily="Roboto,sans-serif"
      >
        {label}
      </Text>
    </HStack>
  );
};

const HeaderBar = observer(() => {
  const store = useContext(MainStore);

  let history = useHistory();

  return (
    <Flex
      p="10px"
      w="100%"
      h="50px"
      boxShadow="xl"
      alignItems="center"
      backgroundColor="#0e1111"
      color="white"
    >
      <HStack ml="40px" display={{ base: 'none', lg: 'flex' }}>
        <Text fontSize="10px" opacity="0.5">
          Powered By:{' '}
        </Text>
        <Image maxH="32px" src={Logo} />
      </HStack>
      <Spacer />

      <Menu>
        <MenuButton as={Box} cursor="pointer">
          <Flex alignItems="center">
            {store.userData && store.userData.user.profile_picture ? (
              <Image
                mr="10px"
                backgroundColor="grey"
                borderRadius="full"
                boxSize="30px"
                src={store.userData.user.profile_picture}
              />
            ) : (
              <Circle mr="10px" size="30px" bg="grey" color="black">
                <Icon as={FaUser} boxSize="18px" circle />
              </Circle>
            )}
            <Box mr="10px">
              {store.userData && store.userData.user.full_name}
            </Box>
            <FaChevronDown />
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem
            color="black"
            icon={<FaUser />}
            onClick={() =>
              // history.push(`/user/profile/${store.userData.user.id}`)
              history.push(`/user/profile/edit`)
            }
          >
            Update Profile
          </MenuItem>
          <MenuDivider />
          <MenuItem
            icon={<HiOutlineLogout />}
            color="red"
            // onClick={() => store._logout(() => history.push('/'))}
            onClick={() => store._logout().then(() => history.push('/'))}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
});
