/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';
import Feedbacks from '../shared/Feedbacks';

const localizer = momentLocalizer(moment);

const Dashboard = observer(() => {
  const store = useContext(MainStore);
  const history = useHistory();
  const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateData = () => {
    //check what type of user
    if (store.userData && store.userData.user.user_type === 'mentee') {
      store._getMenteeBookings({ status: 'pending' }).then(res => setData(res));
    }
  };
  useEffect(() => {
    updateData();
    const interval = setInterval(() => {
      updateData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack p="15px" spacing="12px">
      {/* <Stack alignItems="flex-start" isInline={{ base: false, lg: true }}> */}
      <Flex
        alignItems="flex-start"
        flexDir={{ base: 'column', lg: 'row' }}
        spacing="12px"
      >
        {store.userData && (
          <>
            <Panel flex="2" w="100%">
              <Flex
                alignItems="flex-start"
                p="32px"
                flexDir={{ base: 'column', lg: 'row' }}
              >
                <VStack flex="1" w={{ base: '100%', lg: 'initial' }}>
                  {store.userData.user.profile_picture ? (
                    <Image
                      boxSize="140px"
                      backgroundColor="grey"
                      borderRadius="full"
                      src={store.userData.user.profile_picture}
                      objectFit="cover"
                    />
                  ) : (
                    <Circle size="140px" bg="grey" color="black">
                      <Icon as={FaUser} boxSize="70px" circle />
                    </Circle>
                  )}
                  <Box h="6px" />
                  <Text
                    fontSize="20px"
                    fontFamily="Source Sans Pro"
                    fontWeight="700"
                  >
                    {store.userData.user.full_name}
                  </Text>
                  <Text textTransform="capitalize">
                    {store.userData.user.user_type}
                  </Text>
                </VStack>
                <Stack flex="2" w={{ base: '100%', lg: 'initial' }}>
                  <Stack>
                    <HStack>
                      <Text
                        fontFamily="Source Sans Pro"
                        fontWeight="600"
                        fontSize="18px"
                        color="#444444"
                      >
                        Super Power
                      </Text>
                      <Spacer />
                      <IconButton
                        display="none"
                        variant="link"
                        _focus={{ outline: 'none' }}
                        icon={<FaEdit />}
                      ></IconButton>
                    </HStack>
                    <Divider />
                    <Wrap>
                      {store.userData.user.super_powers &&
                        store.userData.user.super_powers.map((o, i) => {
                          return (
                            <WrapItem>
                              <Text>{`${o}${
                                i ===
                                store.userData.user.super_powers.length - 1
                                  ? ''
                                  : ','
                              }`}</Text>
                            </WrapItem>
                          );
                        })}
                    </Wrap>
                  </Stack>
                  <Stack mt="16px !important">
                    <HStack>
                      <Text
                        fontFamily="Source Sans Pro"
                        fontWeight="600"
                        fontSize="18px"
                        color="#444444"
                      >
                        Skills
                      </Text>
                      <Spacer />
                      <IconButton
                        display="none"
                        variant="link"
                        _focus={{ outline: 'none' }}
                        icon={<FaEdit />}
                      ></IconButton>
                    </HStack>
                    <Divider />
                    <Wrap>
                      {store.userData.user.skills &&
                        store.userData.user.skills.map((o, i) => {
                          return (
                            <WrapItem>
                              <Text>{`${o}${
                                i === store.userData.user.skills.length - 1
                                  ? ''
                                  : ','
                              }`}</Text>
                            </WrapItem>
                          );
                        })}
                    </Wrap>
                  </Stack>
                  <Stack mt="16px !important">
                    <HStack>
                      <Text
                        fontFamily="Source Sans Pro"
                        fontWeight="600"
                        fontSize="18px"
                        color="#444444"
                      >
                        About Me
                      </Text>
                      <Spacer />
                      <IconButton
                        display="none"
                        variant="link"
                        _focus={{ outline: 'none' }}
                        icon={<FaEdit />}
                      ></IconButton>
                    </HStack>
                    <Divider />
                    <Text>{store.userData.user.about_me}</Text>
                  </Stack>
                  <Stack mt="16px !important">
                    <HStack>
                      <Text
                        fontFamily="Source Sans Pro"
                        fontWeight="600"
                        fontSize="18px"
                        color="#444444"
                      >
                        Industry
                      </Text>
                      <Spacer />
                      <IconButton
                        display="none"
                        variant="link"
                        _focus={{ outline: 'none' }}
                        icon={<FaEdit />}
                      ></IconButton>
                    </HStack>
                    <Divider />
                    <Wrap>
                      {store.userData.user.industries &&
                        store.userData.user.industries.map(o => {
                          return (
                            <WrapItem>
                              <Box
                                backgroundColor="#001f3f"
                                color="white"
                                px="12px"
                                py="6px"
                                borderRadius="3px"
                              >
                                <Text fontFamily="Poppins">{o}</Text>
                              </Box>
                            </WrapItem>
                          );
                        })}
                    </Wrap>
                  </Stack>
                </Stack>
              </Flex>
            </Panel>
            <Flex flex="1" color="white" w="100%" display="none">
              <SimpleGrid
                m={{ base: '16px 0px', lg: '0px 16px' }}
                w="100%"
                columns={2}
                spacing={'12px'}
                pointerEvents="none"
              >
                <Box backgroundColor="#00c0ef" w="100%" p="12px" pos="relative">
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="38px"
                    fontFamily="Source Sans Pro"
                    fontWeight="700"
                  >
                    0
                  </Text>
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="15px"
                    fontFamily="Roboto"
                    fontWeight="600"
                  >
                    Total hours
                  </Text>
                  <Icon
                    as={FaRegClock}
                    boxSize="80%"
                    pos="absolute"
                    right="0px"
                    bottom="6px"
                    opacity="0.15"
                    color="black"
                  />
                </Box>
                <Box
                  backgroundColor="#00a65a !important"
                  w="100%"
                  p="12px"
                  pos="relative"
                >
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="38px"
                    fontFamily="Source Sans Pro"
                    fontWeight="700"
                  >
                    {`$ 0`}
                  </Text>
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="15px"
                    fontFamily="Roboto"
                    fontWeight="600"
                  >
                    Your Cash
                  </Text>
                  <Icon
                    as={FaMoneyBill}
                    boxSize="80%"
                    pos="absolute"
                    right="0px"
                    bottom="6px"
                    opacity="0.15"
                    color="black"
                  />
                </Box>
                <Box backgroundColor="#f39c12" w="100%" p="12px" pos="relative">
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="38px"
                    fontFamily="Source Sans Pro"
                    fontWeight="700"
                  >
                    0
                  </Text>
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="15px"
                    fontFamily="Roboto"
                    fontWeight="600"
                  >
                    Tests to Do
                  </Text>
                  <Icon
                    as={FaSuitcase}
                    boxSize="80%"
                    pos="absolute"
                    right="0px"
                    bottom="6px"
                    opacity="0.15"
                    color="black"
                  />
                </Box>
                <Box backgroundColor="#dd4b39" w="100%" p="12px" pos="relative">
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="38px"
                    fontFamily="Source Sans Pro"
                    fontWeight="700"
                  >
                    0
                  </Text>
                  <Text
                    pos="relative"
                    zIndex="1"
                    fontSize="15px"
                    fontFamily="Roboto"
                    fontWeight="600"
                  >
                    Followers
                  </Text>
                  <Icon
                    as={FaUsers}
                    boxSize="80%"
                    pos="absolute"
                    right="0px"
                    bottom="6px"
                    opacity="0.15"
                    color="black"
                  />
                </Box>
              </SimpleGrid>
            </Flex>
          </>
        )}
      </Flex>
      <SimpleGrid
        alignItems="flex-start"
        columns={{ base: 1, lg: 3 }}
        spacing="12px"
      >
        <Panel flex="1" w="100%">
          <Box>
            <Box p="12px">
              <Text
                fontSize="18px"
                fontFamily="Source Sans Pro"
                fontWeight="600"
                color="#444444"
              >
                Schedule
              </Text>
              <Text fontSize="12px" fontFamily="Poppins" color="#999999">
                Pending Requests
              </Text>
            </Box>
            <Divider />
            <Stack>
              {data
                .filter(schedule => {
                  let time = moment
                    .utc(schedule.start_time, 'HH:mm::ss')
                    .local()
                    .format('HH:mm:ss');
                  let date = moment(`${schedule.date_schedule} ${time}`);

                  return date.isSameOrAfter(moment());
                })
                .map(s => {
                  return (
                    <>
                      <Flex flex="1" alignItems="center" p="12px">
                        {s.booked_user ? (
                          <Image
                            mr="10px"
                            backgroundColor="grey"
                            borderRadius="full"
                            boxSize="30px"
                            src={s.booked_user.profile_picture}
                          />
                        ) : (
                          <Circle mr="10px" size="30px" bg="grey" color="black">
                            <Icon as={FaUser} boxSize="18px" circle />
                          </Circle>
                        )}

                        <Box flex="1">
                          <HStack>
                            <Text
                              fontSize="16px"
                              fontFamily="Roboto"
                              fontWeight="600"
                              color="#0e1111"
                            >
                              {store.userData &&
                              store.userData.user.user_type === 'mentee'
                                ? s.mentor && s.mentor.full_name
                                : s.booked_user && s.booked_user.full_name}
                            </Text>
                            <Spacer />
                            <Text
                              fontSize="16px"
                              fontFamily="Poppins"
                              color="#212529"
                            >
                              {moment(
                                `${s.date_schedule} ${moment
                                  .utc(s.start_time, 'HH:mm::ss')
                                  .local()
                                  .format('HH:mm:ss')}`
                              ).format('LLL')}
                            </Text>
                          </HStack>
                          <Text
                            fontSize="16px"
                            fontFamily="Poppins"
                            color="#999999"
                          >
                            {`Startup: ${s.title}`}
                          </Text>
                        </Box>
                      </Flex>
                      <Divider />
                    </>
                  );
                })}
            </Stack>
            <Box p="12px">
              <Button
                _focus={{ boxShadow: 'none' }}
                variant="link"
                isFullWidth
                onClick={() => history.push('/user/schedules')}
              >
                View All Schedules
              </Button>
            </Box>
          </Box>
        </Panel>
        <Feedbacks />
        <Panel flex="1" w="100%" display="none">
          <Box>
            <Box p="12px">
              <Text
                fontSize="18px"
                fontFamily="Source Sans Pro"
                fontWeight="600"
                color="#444444"
              >
                Badges
              </Text>
              <Text fontSize="12px" fontFamily="Poppins" color="#999999">
                Ongoing
              </Text>
            </Box>
            <Divider />
            <Stack>
              {[0, 0, 0, 0].map((s, i) => {
                return (
                  <>
                    <Box p="12px">
                      <HStack>
                        <Text
                          fontSize="16px"
                          fontFamily="Poppins"
                          fontWeight="600"
                          color="#0e1111"
                        >
                          {`Badge ${i}`}
                        </Text>
                        <Spacer />
                        <Text
                          fontSize="16px"
                          fontFamily="Poppins"
                          fontWeight="600"
                          color="#0e1111"
                        >
                          {`80/100`}
                        </Text>
                      </HStack>
                      <Progress hasStripe value={80} />
                    </Box>
                    <Divider />
                  </>
                );
              })}
            </Stack>
            <Box p="12px">
              <Button _focus={{ boxShadow: 'none' }} variant="link" isFullWidth>
                View All Badges
              </Button>
            </Box>
          </Box>
        </Panel>
      </SimpleGrid>
    </Stack>
  );
});

const MyCalendar = () => {
  return (
    <Box px="20px" py="12px" backgroundColor="#fff" boxShadow="md">
      <Text fontWeight="bold" color="#FBC02C">
        My Calendar
      </Text>
      <Box backgroundColor="#FBC02C" w="100%" h="2px" mt="8px" mb="12px"></Box>
      <Calendar
        localizer={localizer}
        // events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </Box>
  );
};

export default Dashboard;
