/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect } from 'react';

import {
  Box,
  Text,
  HStack,
  useDisclosure,
  Icon,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Image,
  Circle,
} from '@chakra-ui/react';

import { FaTrash, FaEye, FaCheck, FaVideo, FaUser } from 'react-icons/fa';

import MainStore from '../stores/MainStore';

import RequestInfoModal from './RequestInfoModal';

import moment from 'moment';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import 'react-quill/dist/quill.snow.css'; // ES6

const MentorRequests = ({ status, tab }) => {
  const store = useContext(MainStore);
  const [data, setData] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const updateData = () => {
    store._getMentorBookings({ status: status }).then(res => setData(res));
  };

  useEffect(() => {
    updateData();

    return () => {};
  }, [tab]);

  return (
    <Box>
      <RequestInfoModal
        title="Request Info"
        type="mentor"
        onClose={onClose}
        isOpen={isOpen}
        appointment={selected}
      />
      <Table variant="simple">
        <TableCaption>
          {data.length <= 0 ? 'No Requests Available' : ''}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Photo</Th>
            <Th>Name</Th>
            <Th>Title</Th>
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Status</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((d, i) => {
            return (
              <Tr>
                <Td>
                  {d.booked_user.profile_picture ? (
                    <Image
                      cursor="pointer"
                      boxSize="60px"
                      objectFit="cover"
                      src={
                        d.booked_user.profile_picture
                          ? d.booked_user.profile_picture
                          : ''
                      }
                      alt="Thumbnail"
                      ignoreFallback
                      borderRadius="full"
                    />
                  ) : (
                    <Circle
                      boxSize="60px"
                      border="1px solid gray"
                      backgroundColor="white"
                      cursor="pointer"
                      _hover={{ opacity: 0.8 }}
                    >
                      <Icon as={FaUser} w={'40px'} h={'40px'} opacity="0.5" />
                    </Circle>
                  )}
                </Td>
                <Td>
                  <Text>{d.booked_user.full_name}</Text>
                </Td>
                <Td>
                  <Text>{d.title}</Text>
                </Td>
                <Td>
                  {' '}
                  <Text>{moment(d.date_schedule).format('LL')}</Text>
                </Td>
                <Td>
                  <Text>{`${moment
                    .utc(d.start_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')} - ${moment
                    .utc(d.end_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')}`}</Text>
                </Td>
                <Td>
                  {' '}
                  <Text>{d.status_name}</Text>
                </Td>
                <Td>
                  <HStack spacing="10px">
                    <IconButton
                      isLoading={loading}
                      colorScheme="yellow"
                      color="white"
                      icon={<FaEye />}
                      onClick={() => {
                        setSelected(d);
                        onOpen();
                      }}
                    />
                    {status === 'pending' && (
                      <IconButton
                        isLoading={loading}
                        colorScheme="green"
                        icon={<FaCheck />}
                        onClick={() => {
                          setLoading(true);
                          store
                            ._updateBooking({
                              id: d.appointment_id,
                              status: 'Accepted',
                            })
                            .then(res => {
                              setLoading(false);
                              updateData();
                            });
                        }}
                      />
                    )}
                    {status === 'accepted' && (
                      <IconButton
                        isLoading={loading}
                        colorScheme="blue"
                        icon={<FaVideo />}
                        onClick={() => {
                          // setLoading(true);
                          store
                            ._meeting({
                              action: 'start',
                              appointment_id: d.appointment_id,
                              // meeting_id: d.mentoring_room.meetingId,
                              // password: d.mentoring_room.moderatorPW,
                            })
                            .then(res => {
                              if (store.debugMode) console.log('BBB', res);
                              if (res) {
                                store
                                  ._addComment({
                                    id: d.appointment_id,
                                    comment: `${store.userData.user.full_name} has joined the meeting`,
                                  })
                                  .then(res => {
                                    if (res) {
                                      updateData();
                                    }
                                  });
                                window.open(res.url);
                              }
                            });
                        }}
                      />
                    )}
                    <IconButton
                      display="none"
                      isLoading={loading}
                      colorScheme="red"
                      icon={<FaTrash />}
                      onClick={() => {
                        setLoading(true);
                        store
                          ._deleteAppointment({ id: d.appointment_id })
                          .then(res => {
                            setLoading(false);
                            updateData();
                          });
                      }}
                    />
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MentorRequests;
