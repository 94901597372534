/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect } from 'react';

import {
  Box,
  Text,
  HStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Switch as CSwitch,
  Link as Anchor,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import MentorRequests from './MentorRequests';
import MenteeRequests from './MenteeRequests';

import 'flatpickr/dist/themes/airbnb.css';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import 'react-quill/dist/quill.snow.css'; // ES6

const MySchedules = () => {
  const store = useContext(MainStore);

  useEffect(() => {
    store._getMySchedules();
    return () => {};
  }, []);

  return (
    <Box>
      {store.userData && store.userData.user.user_type === 'mentor' ? (
        <MentorSchedule />
      ) : (
        <MenteeSchedule />
      )}
    </Box>
  );
};

const MentorSchedule = () => {
  const store = useContext(MainStore);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    return () => {};
  });

  return (
    <Box p="10px">
      <HStack alignItems="center" spacing="14px">
        <Text fontSize="24px" fontFamily="Roboto" fontWeight="500">
          My Schedule
        </Text>
        <Button colorScheme="blue" as={Link} to={`mentor-availability`}>
          Edit Schedule
        </Button>
      </HStack>
      <Panel p="20px" mt="20px">
        <Box>
          <Tabs variant="enclosed" onChange={i => setIndex(i)}>
            <TabList>
              <Tab>Pending Requests</Tab>
              <Tab>Accepted Requests</Tab>
              <Tab>Completed Requests</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <MentorRequests status="pending" tab={index} />
              </TabPanel>
              <TabPanel>
                <MentorRequests status="accepted" tab={index} />
              </TabPanel>
              <TabPanel>
                <MentorRequests status="completed" tab={index} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Panel>
    </Box>
  );
};

const MenteeSchedule = () => {
  const store = useContext(MainStore);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    return () => {};
  });

  return (
    <Box p="10px">
      <HStack spacing="14px">
        <Text fontSize="24px" fontFamily="Roboto" fontWeight="500">
          My Requests
        </Text>
        <Button colorScheme="blue" as={Link} to={`mentoring-form`}>
          Request Mentoring
        </Button>
      </HStack>
      <Panel p="20px" mt="20px">
        <Box>
          <Tabs variant="enclosed" onChange={i => setIndex(i)}>
            <TabList>
              <Tab>Pending Requests</Tab>
              <Tab>Accepted Requests</Tab>
              <Tab>Completed Requests</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <MenteeRequests status="pending" tab={index} />
              </TabPanel>
              <TabPanel>
                <MenteeRequests status="accepted" tab={index} />
              </TabPanel>
              <TabPanel>
                <MenteeRequests status="completed" tab={index} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Panel>
    </Box>
  );
};

export default MySchedules;
