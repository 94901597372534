/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import RequestInfoModal from './RequestInfoModal';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const MenteeRequests = ({ status, tab }) => {
  const store = useContext(MainStore);
  const [data, setData] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const updateData = () => {
    store._getMenteeBookings({ status: status }).then(res => setData(res));
  };

  useEffect(() => {
    updateData();
    return () => {};
  }, [tab]);

  return (
    <Box>
      <RequestInfoModal
        title="Request Info"
        type="mentee"
        onClose={onClose}
        isOpen={isOpen}
        appointment={selected}
      />
      <Table variant="simple">
        <TableCaption>
          {data.length <= 0 ? 'No Requests Available' : ''}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Photo</Th>
            <Th>Name</Th>
            <Th>Title</Th>
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Status</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((d, i) => {
            return (
              <Tr>
                <Td>
                  {d.mentor.profile_picture ? (
                    <Image
                      cursor="pointer"
                      boxSize="60px"
                      objectFit="cover"
                      src={
                        d.mentor.profile_picture ? d.mentor.profile_picture : ''
                      }
                      alt="Thumbnail"
                      ignoreFallback
                      borderRadius="full"
                    />
                  ) : (
                    <Circle
                      boxSize="60px"
                      border="1px solid gray"
                      backgroundColor="white"
                      cursor="pointer"
                      _hover={{ opacity: 0.8 }}
                    >
                      <Icon as={FaUser} w={'40px'} h={'40px'} opacity="0.5" />
                    </Circle>
                  )}
                </Td>
                <Td>
                  <Text>{d.mentor.full_name}</Text>
                </Td>
                <Td>
                  <Text>{d.title}</Text>
                </Td>
                <Td>
                  {' '}
                  <Text>{moment(d.date_schedule).format('LL')}</Text>
                </Td>
                <Td>
                  <Text>{`${moment
                    .utc(d.start_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')} - ${moment
                    .utc(d.end_time, 'HH:mm::ss')
                    .local()

                    .format('h:mm a')}`}</Text>
                </Td>
                <Td>
                  {' '}
                  <Text>{d.status_name}</Text>
                </Td>
                <Td>
                  <HStack spacing="10px">
                    <IconButton
                      colorScheme="yellow"
                      color="white"
                      icon={<FaEye />}
                      onClick={() => {
                        setSelected(d);
                        onOpen();
                      }}
                    />
                    {status === 'accepted' && (
                      <IconButton
                        isLoading={loading}
                        colorScheme="blue"
                        icon={<FaVideo />}
                        onClick={() => {
                          // setLoading(true);
                          store
                            ._meeting({
                              action: 'join',
                              appointment_id: d.appointment_id,
                              // meeting_id: d.mentoring_room.meetingId,
                              // password: d.mentoring_room.attendeePW,
                            })
                            .then(res => {
                              if (store.debugMode) console.log('BBB', res);
                              if (res) {
                                if (res.url === 'not started') {
                                  // alert('Mentor has not started');
                                  store._globalToast({
                                    title: 'Mentor has not started the meeting',
                                    status: 'error',
                                  });
                                } else {
                                  store
                                    ._addComment({
                                      id: d.appointment_id,
                                      comment: `${store.userData.user.full_name} has joined the meeting`,
                                    })
                                    .then(res => {
                                      if (res) {
                                        updateData();
                                      }
                                    });
                                  window.open(res.url);
                                }
                              }
                            });
                        }}
                      />
                    )}
                    {/* {status === 'pending' && ( */}
                    <IconButton
                      display="none"
                      colorScheme="red"
                      icon={<FaTrash />}
                      onClick={() => {
                        store
                          ._deleteAppointment({ id: d.appointment_id })
                          .then(res =>
                            store
                              ._getMenteeBookings({ status: status })
                              .then(res => setData(res))
                          );
                      }}
                    />
                    {/* )} */}
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MenteeRequests;
