/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  CheckboxGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  TableCaption,
  IconButton,
  Spinner,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Divider,
  SimpleGrid,
  Progress,
  Link as Anchor,
  useBreakpointValue,
  Collapse,
} from '@chakra-ui/react';

import {
  FaLock,
  FaChevronDown,
  FaTrash,
  FaEye,
  FaCheck,
  FaCross,
  FaEdit,
  FaImage,
  FaColumns,
  FaRegCalendarCheck,
  FaStackExchange,
  FaBook,
  FaVideo,
  FaIdBadge,
  FaUserPlus,
  FaUser,
  FaUserFriends,
  FaRegListAlt,
  FaRegStickyNote,
  FaRegBell,
  FaMoneyBill,
  FaClock,
  FaSuitcase,
  FaUsers,
  FaCocktail,
  FaUserTimes,
  FaRegClock,
  FaPaperclip,
  FaPlus,
  FaCalendarAlt,
  FaEyeSlash,
  FaSearch,
} from 'react-icons/fa';

import { FiMenu, FiUsers } from 'react-icons/fi';

import { HiOutlineLogout } from 'react-icons/hi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Panel from '../shared/Panel';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import Logo from '../assets/svhq_logo.png';
import LogoGlobe from '../assets/svhq_logo_globe.png';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import ReactTags from 'react-tag-autocomplete';

// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
// import { ReactTabulator, reactFormatter } from 'react-tabulator';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const EditProfile = () => {
  const store = useContext(MainStore);
  const location = useLocation();
  const { path } = useRouteMatch();

  const [file, setfile] = useState();
  const [image, setimage] = useState();

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [aboutme, setaboutme] = useState('');
  const [address, setaddress] = useState('');
  const [city, setcity] = useState('');
  const [postal, setpostal] = useState('');
  const [state, setstate] = useState('');
  const [country, setcountry] = useState('');
  const [mobile, setmobile] = useState('');
  const [company, setcompany] = useState('');
  const [social, setsocials] = useState([]);
  const [supers, setsupers] = useState([]);
  const [skills, setskills] = useState([]);
  const [industries, setindustries] = useState([]);

  useEffect(() => {
    if (location.pathname === path) {
      //populate fields
      if (store.userData) {
        setfirstname(store.userData.user.first_name);
        setlastname(store.userData.user.last_name);
        // setindustry(store.userData.user.industry);
        setcompany(store.userData.user.company);
        setaboutme(store.userData.user.about_me);
        setaddress(store.userData.user.address_line_1);
        setcity(store.userData.user.city);
        setpostal(store.userData.user.postal_code);
        setstate(store.userData.user.state);
        setcountry(store.userData.user.country);
        setmobile(store.userData.user.phone_number);
        setimage(store.userData.user.profile_picture);

        if (store.userData.user.super_powers) {
          setsupers(
            store.userData.user.super_powers.map(sp => {
              return { name: sp };
            })
          );
        }

        if (store.userData.user.skills) {
          setskills(
            store.userData.user.skills.map(skill => {
              return { name: skill };
            })
          );
        }

        if (store.userData.user.industries) {
          setindustries(
            store.userData.user.industries.map(i => {
              return { name: i };
            })
          );
        }
      }
    }

    return () => {};
  }, [location]);

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    setimage(URL.createObjectURL(files[0]));
    setfile(files[0]);
  };

  const onFilesError = (error, file) => {
    if (store.debugMode)
      console.log('error code ' + error.code + ': ' + error.message);
  };

  const updateProfile = () => {
    store
      ._updateProfile({
        first_name: firstname,
        last_name: lastname,
        // industry,
        about_me: aboutme,
        address_line_1: address,
        city,
        postal_code: postal,
        state,
        country,
        phone_number: mobile,
        company,
        super_powers: supers,
        skills,
        industries,
        profile_picture: file,
      })
      .then(res => {
        // if(res)
      });
  };

  return (
    <Box p="12px" backgroundColor="white">
      <HStack>
        <Text fontSize="24px" fontWeight="bold">
          My Profile
        </Text>
        <Spacer />
        <Button colorScheme="blue" onClick={updateProfile}>
          Update Profile
        </Button>
      </HStack>
      <Stack>
        <VStack>
          <Files
            className="files-dropzone"
            onChange={files => onFilesChange(files)}
            onError={onFilesError}
            accepts={['image/*']}
            multiple={false}
            // maxFileSize={10000000}
            // minFileSize={0}
            clickable
          >
            {image ? (
              <Image
                cursor="pointer"
                boxSize="150px"
                objectFit="cover"
                src={image ? image : ''}
                alt="Thumbnail"
                ignoreFallback
                borderRadius="full"
              />
            ) : (
              <Circle
                boxSize="150px"
                border="1px solid gray"
                backgroundColor="white"
                cursor="pointer"
                _hover={{ opacity: 0.8 }}
              >
                <Icon as={FaUser} w={'40px'} h={'40px'} />
              </Circle>
            )}
          </Files>
        </VStack>
        {/* FIELDS */}
        <Flex mt="20px !important" flexDir={{ base: 'column', lg: 'row' }}>
          <Stack flex="1" spacing="10px" px="10px">
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                placeholder="First Name"
                value={firstname}
                onChange={e => setfirstname(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                placeholder="Last Name"
                value={lastname}
                onChange={e => setlastname(e.target.value)}
              />
            </FormControl>
            {/* <FormControl isRequired>
                <FormLabel>Industry</FormLabel>
                <Input
                  type="text"
                  placeholder="Industry"
                  value={industry}
                  onChange={e => setindustry(e.target.value)}
                />
              </FormControl> */}
            <FormControl isRequired>
              <FormLabel>Company</FormLabel>
              <Input
                type="text"
                placeholder="Company"
                value={company}
                onChange={e => setcompany(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>About Me</FormLabel>
              <Input
                type="text"
                placeholder="About Me"
                value={aboutme}
                onChange={e => setaboutme(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Super Powers</FormLabel>
              <ReactTags
                placeholderText="Add Super Powers"
                tags={supers}
                onDelete={tagIndex => {
                  setsupers(supers.filter((_, i) => i !== tagIndex));
                }}
                onAddition={newTag => {
                  setsupers([...supers, newTag]);
                }}
                allowNew
                newTagText={`Add Super Power:`}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Skills</FormLabel>
              <ReactTags
                placeholderText="Add Skills"
                tags={skills}
                onDelete={tagIndex => {
                  setskills(skills.filter((_, i) => i !== tagIndex));
                }}
                onAddition={newTag => {
                  setskills([...skills, newTag]);
                }}
                allowNew
                newTagText={`Add Skill:`}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Industry</FormLabel>
              <ReactTags
                placeholderText="Add:"
                tags={industries}
                onDelete={tagIndex => {
                  setindustries(industries.filter((_, i) => i !== tagIndex));
                }}
                onAddition={newTag => {
                  setindustries([...industries, newTag]);
                }}
                allowNew
                newTagText={`Add Industry:`}
              />
            </FormControl>
          </Stack>
          <Stack
            flex="1"
            spacing="10px"
            px="10px"
            mt={{ base: '10px !important', lg: '0px !important' }}
          >
            <FormControl isRequired>
              <FormLabel>Address</FormLabel>
              <Input
                type="text"
                placeholder="Address"
                value={address}
                onChange={e => setaddress(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>City</FormLabel>
              <Input
                type="text"
                placeholder="City"
                value={city}
                onChange={e => setcity(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Postal Code</FormLabel>
              <Input
                type="text"
                placeholder="Postal Code"
                value={postal}
                onChange={e => setpostal(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>State</FormLabel>
              <Input
                type="text"
                placeholder="State"
                value={state}
                onChange={e => setstate(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Country</FormLabel>
              <Input
                type="text"
                placeholder="Country"
                value={country}
                onChange={e => setcountry(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="text"
                placeholder="Phone Number"
                value={mobile}
                onChange={e => setmobile(e.target.value)}
              />
            </FormControl>
          </Stack>
        </Flex>

        {/* <ReactTags
            tags={tags}
            onDelete={newTag => {
              setTags([...tags, newTag]);
            }}
            onAddition={newTag => {
              setTags([...tags, newTag]);
            }}
            allowNew
          /> */}
      </Stack>
    </Box>
  );
};

export default EditProfile;
