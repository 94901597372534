/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Image,
  Square,
  Wrap,
  WrapItem,
  Switch as CSwitch,
} from '@chakra-ui/react';

import { FaEdit, FaImage } from 'react-icons/fa';

import { AiFillFileAdd } from 'react-icons/ai';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import MainStore from '../stores/MainStore';

import Files from 'react-files';

const FeaturedCareers = ({ title, subtitle, path }) => {
  const store = useContext(MainStore);
  let location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState();
  const [label, setlabel] = useState('');
  const [company, setcompany] = useState('');
  const [position, setposition] = useState('');
  const [url, seturl] = useState('');
  const [order, setorder] = useState(null);
  const [featured, setfeatured] = useState(false);
  const [image, setimage] = useState('');
  const [file, setFile] = useState(null);

  const [loading, setloading] = useState(false);

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    setimage(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  const clearFields = () => {
    setlabel('');
    setcompany('');
    setposition('');
    seturl('');
    setorder('');
    setfeatured(false);
    setimage('');
    setSelected(null);
    setimage('');
    setFile(null);
  };

  const updateData = () => {
    store._getCareers().then(res => {
      if (store.userData.user.user_type === 'admin') {
        setData(res.data.sort((a, b) => a.priority - b.priority));
      } else {
        setData(
          res.data
            .filter(d => d.feature)
            .sort((a, b) => a.priority - b.priority)
        );
      }
    });
  };

  useEffect(() => {
    if (location.pathname === path) {
      updateData();
    }
    return () => {};
  }, [location]);

  return (
    <Box px="36px" py="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected ? 'Edit' : 'Add New'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="10px">
              <Files
                className="files-dropzone"
                onChange={files => onFilesChange(files)}
                onError={onFilesError}
                accepts={['image/*']}
                multiple={false}
                // maxFileSize={10000000}
                // minFileSize={0}
                clickable
              >
                {image ? (
                  <Image
                    m="0 auto"
                    cursor="pointer"
                    boxSize="200px"
                    objectFit="cover"
                    src={image ? image : ''}
                    alt="Thumbnail"
                    ignoreFallback
                  />
                ) : (
                  <Square
                    m="0 auto"
                    boxSize="200px"
                    border="1px solid gray"
                    borderRadius={4}
                    backgroundColor="white"
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                  >
                    <Icon
                      as={FaImage}
                      w={'40px'}
                      h={'40px'}
                      // mb={5}
                    />
                  </Square>
                )}
              </Files>

              <Input
                value={label}
                onChange={e => setlabel(e.target.value)}
                type="text"
                placeholder="Title"
              />
              <Input
                value={company}
                onChange={e => setcompany(e.target.value)}
                type="text"
                placeholder="Company Type"
              />
              <Input
                value={position}
                onChange={e => setposition(e.target.value)}
                type="text"
                placeholder="Position"
              />
              <Input
                value={url}
                onChange={e => seturl(e.target.value)}
                type="text"
                placeholder="URL"
              />
              <Input
                value={order}
                onChange={e => setorder(e.target.value)}
                type="number"
                placeholder="Order"
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={featured}
                  onChange={e => setfeatured(e.target.checked)}
                />
                <Text>Featured</Text>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {selected ? (
              <>
                <Button
                  isLoading={loading}
                  colorScheme="red"
                  mr={3}
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateCareer({
                        id: selected.id,
                        delete: true,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Delete
                </Button>
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateCareer({
                        title: label,
                        company_type: company,
                        position,
                        url,
                        priority: order,
                        feature: featured,
                        thumbnail: file,
                        id: selected.id,
                        edit: true,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                isLoading={loading}
                loadingText="Saving..."
                colorScheme="blue"
                onClick={() => {
                  setloading(true);
                  store
                    ._updateCareer({
                      title: label,
                      company_type: company,
                      position,
                      url,
                      priority: order,
                      feature: featured,
                      thumbnail: file,
                    })
                    .then(res => {
                      setloading(false);
                      if (res) {
                        updateData();
                        clearFields();
                        onClose();
                      }
                    });
                }}
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent="flex-start"
      >
        {store.userData && store.userData.user.user_type === 'admin' && (
          <IconButton
            mr="10px"
            icon={<AiFillFileAdd />}
            colorScheme="blue"
            onClick={() => {
              onOpen();
              setlabel('');
              seturl('');
              setorder('');
              setfeatured(false);
              setimage('');
              setSelected(null);
            }}
          />
        )}

        <Text fontSize="24px" fontWeight="bold">
          {title}
        </Text>
        <Text ml={{ base: '0px', lg: '10px' }} fontSize="16px" color="grey">
          {subtitle}
        </Text>
      </Flex>
      {data.length <= 0 && <Text mt="20px">No Data Available</Text>}
      <Wrap mt="20px" spacing="40px">
        {data.map((d, i) => {
          return (
            <WrapItem key={`w${i}`}>
              <Box
                w="240px"
                h="240px"
                onClick={() => {
                  window.open(d.url);
                }}
                cursor="pointer"
                pos="relative"
              >
                {d.thumbnail ? (
                  <Box
                    h="60%"
                    backgroundColor="black"
                    backgroundImage={d.thumbnail}
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                    opacity={d.feature ? 1 : 0.5}
                  >
                    {/* <Image
                      m="0 auto"
                      h="100%"
                      src={d.thumbnail}
                      objectFit="cover"
                    /> */}
                  </Box>
                ) : (
                  <Box
                    h="60%"
                    backgroundColor="black"
                    opacity={d.feature ? 1 : 0.5}
                  >
                    <Center h="100%">
                      <Text color="white">Image Unavailable</Text>
                    </Center>
                  </Box>
                )}

                {store.userData && store.userData.user.user_type === 'admin' && (
                  <IconButton
                    pos="absolute"
                    top="4px"
                    right="4px"
                    icon={<FaEdit />}
                    colorScheme="blue"
                    zIndex="99"
                    onClick={e => {
                      e.stopPropagation();
                      setlabel(data[i].title);
                      seturl(data[i].url);
                      setorder(data[i].priority);
                      setfeatured(data[i].feature);
                      setimage(data[i].thumbnail);
                      setSelected(data[i]);
                      onOpen();
                    }}
                  />
                )}

                <Box
                  pos="relative"
                  h="40%"
                  borderLeft="5px solid #FBC02C"
                  p="6px"
                >
                  <Text lineHeight="18px">{d.title}</Text>
                  <Box pos="absolute" right="8px" bottom="6px">
                    <Flex alignItems="center">
                      <Box textAlign="right" mr="6px">
                        <Text fontSize="14px" fontWeight="bold">
                          {d.company_type ? d.company_type : 'N/A'}
                        </Text>
                        <Text fontSize="12px">
                          {d.position ? d.position : 'N/A'}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
                {store.userData &&
                  store.userData.user.user_type === 'admin' && (
                    <Text>{`Order: ${d.priority ?? 'Not Set'}`}</Text>
                  )}
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};

export default FeaturedCareers;
