/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import { FaUser } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const MentorList = ({ setMentor, isOpen, onClose }) => {
  const store = useContext(MainStore);

  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    if (isOpen) {
      store._getMembersSearch({}).then(res => {
        setMentors(
          res.data
            .filter(d => d.user_type === 'mentor')
            .sort((a, b) => a.first_name.localeCompare(b.first_name))
        );
      });
    }
    return () => {};
  }, [isOpen]);

  return (
    <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Mentor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {mentors.length <= 0 && (
            <VStack>
              <Spinner />
            </VStack>
          )}
          <Stack spacing="10px">
            {mentors.map((m, i) => {
              return (
                <>
                  <HStack
                    spacing="14px"
                    _hover={{ backgroundColor: 'rgba(255,255,255,0.15)' }}
                  >
                    {/* <Icon as={FaUser} w="30px" h="30px" /> */}
                    <Image
                      backgroundColor="grey"
                      borderRadius="full"
                      boxSize="60px"
                      src={m.profile_picture}
                      ignoreFallback
                      // alt={m.full_name.charAt(0)}
                    />
                    <Stack maxW="320px">
                      <Text fontFamily="Source Sans Pro" fontWeight="600">
                        {`${m.first_name} ${m.last_name}`}
                      </Text>
                      <Text fontFamily="Poppins" fontSize="14px">
                        {m.about_me}
                      </Text>
                      <HStack>
                        <Text
                          fontFamily="Poppins"
                          fontSize="14px"
                          fontWeight="500"
                        >
                          Super Powers:
                        </Text>
                        <Text>
                          {m.super_powers && m.super_powers.join(', ')}
                        </Text>
                      </HStack>
                    </Stack>
                    <Spacer />
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        setMentor(m);
                        onClose();
                      }}
                    >
                      Select
                    </Button>
                  </HStack>
                  <Divider />
                </>
              );
            })}
          </Stack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MentorList;
