/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Button,
  Icon,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Circle,
  Switch as CSwitch,
  Divider,
  Link as Anchor,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
  Tag,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import Panel from '../shared/Panel';

import {
  FaUser,
  FaCalendarAlt,
  FaRegClock,
  FaSearch,
  FaPlus,
} from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import ViewWebinar from './ViewWebinar';
import EventsScreen from './EventsScreen';
import WebinarAdminView from './WebinarAdminView';

import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Select from 'react-select';

const WebinarScreen = () => {
  const store = useContext(MainStore);
  const [index, setIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState();

  return (
    <Box p="12px">
      {store.userData && store.userData.user.user_type === 'admin' && (
        <Button
          as={Link}
          to={`new-webinar`}
          leftIcon={<FaPlus />}
          colorScheme="blue"
        >
          Create New Webinar
        </Button>
      )}
      <Panel mt="12px">
        <Box p="12px">
          <Tabs variant="enclosed" onChange={i => setIndex(i)}>
            <TabList>
              <Tab>Incoming Events</Tab>
              {store.userData && store.userData.user.user_type !== 'admin' && (
                <Tab>My Webinars</Tab>
              )}
              {store.userData && store.userData.user.user_type === 'admin' && (
                <Tab>Created Events</Tab>
              )}
              {store.userData && store.userData.user.user_type === 'mentor' && (
                <Tab>Webinar Requests</Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <EventsScreen action="_getIncomingEvents" tab={index} />
              </TabPanel>
              {store.userData && store.userData.user.user_type !== 'admin' && (
                <TabPanel>
                  <EventsScreen action="_getMyWebinars" tab={index} />
                </TabPanel>
              )}
              {store.userData && store.userData.user.user_type === 'admin' && (
                <TabPanel>
                  <WebinarAdminView user_type="admin" tab={index} />
                </TabPanel>
              )}
              {store.userData && store.userData.user.user_type === 'mentor' && (
                <TabPanel>
                  <WebinarAdminView user_type="mentor" tab={index} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </Panel>
    </Box>
  );
};

export default WebinarScreen;
