import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  theme,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Image,
  Spinner,
} from '@chakra-ui/react';

import { FaUser, FaLock, FaMailBulk } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from './stores/MainStore';

//sreens
import UserScreen from './User/index';

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box h="100%">
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <PrivateRoute path="/">
              <Main />
            </PrivateRoute>
          </Switch>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = observer(({ children, ...rest }) => {
  const store = useContext(MainStore);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        store.userData ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

const LoginPage = observer(() => {
  let history = useHistory();
  let location = useLocation();
  let store = useContext(MainStore);

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  let { from } = location.state || { from: { pathname: '/' } };
  let login = () => {
    setLoading(true);
    store._login({ username, password }).then(res => {
      setLoading(false);
      if (res) history.replace(from);
    });
  };

  useEffect(() => {
    if (store.userData) {
      history.replace(from);
    } else {
      setTimeout(() => setShow(true), 2000);
    }
    return () => {};
  }, [store.userData]);

  return (
    <>
      {!show ? (
        <VStack w="100vw" h="90vh" justifyContent="center" alignItems="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#fbc02d"
            size="xl"
          />
        </VStack>
      ) : (
        <Box p={4} maxW="md" m="0 auto" pt="10%">
          <VStack spacing={2}>
            {store.whitelabel && (
              <>
                <Image
                  src={store.whitelabel.logo_path}
                  alt={store.whitelabel.title}
                />
                <Text fontSize="24px" fontWeight="bold">
                  {store.whitelabel.title}
                </Text>
              </>
            )}
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaMailBulk color="gray.300" />}
              />
              <Input
                value={username}
                onChange={e => setusername(e.target.value)}
                type="text"
                placeholder="Email"
              />
            </InputGroup>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaLock color="gray.300" />}
              />
              <Input
                value={password}
                onChange={e => setpassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </InputGroup>

            <Button
              backgroundColor="#FBC02C"
              colorScheme="yellow"
              onClick={login}
              isLoading={loading}
              loadingText="Logging in..."
              isFullWidth
            >
              Login
            </Button>
            <Text>
              Don't have an account yet?{' '}
              <Link style={{ color: 'blue' }} to="/register">
                Register Here
              </Link>
            </Text>
          </VStack>
        </Box>
      )}
    </>
  );
});

const RegisterPage = observer(() => {
  let history = useHistory();
  let location = useLocation();
  let store = useContext(MainStore);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [loading, setLoading] = useState(false);

  let { from } = location.state || { from: { pathname: '/' } };
  let register = () => {
    setLoading(true);
    store._register({ firstName, lastName, username, password }).then(res => {
      setLoading(false);
      if (res) history.replace('/');
    });
  };

  return (
    <Box p={4} maxW="md" m="0 auto" pt="10%">
      <VStack spacing={2}>
        {store.whitelabel && (
          <Text fontSize="24px" fontWeight="bold">
            {store.whitelabel.title}
          </Text>
        )}
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaUser color="gray.300" />}
          />
          <Input
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            type="text"
            placeholder="First Name"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaUser color="gray.300" />}
          />
          <Input
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            type="text"
            placeholder="Last Name"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaMailBulk color="gray.300" />}
          />
          <Input
            value={username}
            onChange={e => setusername(e.target.value)}
            type="text"
            placeholder="Email"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaLock color="gray.300" />}
          />
          <Input
            value={password}
            onChange={e => setpassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
        </InputGroup>

        <Button
          backgroundColor="#FBC02C"
          colorScheme="yellow"
          onClick={register}
          isLoading={loading}
          loadingText="Registering..."
          isFullWidth
        >
          Register
        </Button>
        <Text>
          Already have an account?{' '}
          <Link style={{ color: 'blue' }} to="/login">
            Log in
          </Link>
        </Text>
      </VStack>
    </Box>
  );
});

function Main() {
  let history = useHistory();
  let { path } = useRouteMatch();

  useEffect(() => {
    //force here depending on user type
    history.replace(window.location.pathname);
    return () => {};
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        {/* <Text>Home</Text> */}
        <ToDashboard />
      </Route>
      <Route path={'/user'}>
        <UserScreen />
      </Route>
    </Switch>
  );
}

function ToDashboard() {
  let history = useHistory();

  useEffect(() => {
    history.replace('/user');
    return () => {};
  }, []);

  return <></>;
}
