/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Image,
  Circle,
  Wrap,
  WrapItem,
  Switch as CSwitch,
} from '@chakra-ui/react';

import { FaUser, FaEdit } from 'react-icons/fa';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

export default ({ title, subtitle, path, toGet }) => {
  const store = useContext(MainStore);
  let location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState();

  const [order, setorder] = useState(null);
  const [featured, setfeatured] = useState(false);
  const [loading, setloading] = useState(false);

  const updateData = () => {
    store[toGet]().then(res => {
      if (store.debugMode) console.log('GET!', res);

      if (store.userData.user.user_type === 'admin') {
        setData(res.data.sort((a, b) => a.priority - b.priority));
      } else {
        setData(
          res.data
            .filter(d => d.feature)
            .sort((a, b) => a.priority - b.priority)
        );
      }
    });
  };

  useEffect(() => {
    if (location.pathname === path) {
      if (store.debugMode) console.log('FEATURED!', title, location.pathname);
      updateData();
    }
    return () => {};
  }, [location]);

  return (
    <Box px="36px" py="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected ? 'Edit' : 'Add New'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="10px">
              <Input
                value={order}
                onChange={e => setorder(e.target.value)}
                type="number"
                placeholder="Order"
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={featured}
                  onChange={e => setfeatured(e.target.checked)}
                />
                <Text>Featured</Text>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={loading}
              loadingText="Updating..."
              colorScheme="blue"
              onClick={() => {
                setloading(true);
                store
                  ._updatePitch({
                    feature: featured,
                    id: selected.id,
                    priority: order,
                  })
                  .then(res => {
                    setloading(false);
                    if (res) {
                      onClose();
                      updateData();
                    }
                  });
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent="flex-start"
      >
        <Text fontSize="24px" fontWeight="bold">
          {title}
        </Text>
        <Text ml={{ base: '0px', lg: '10px' }} fontSize="16px" color="grey">
          {subtitle}
        </Text>
      </Flex>
      {data.length <= 0 && <Text mt="20px">No Data Available</Text>}
      <Wrap mt="20px" spacing="40px">
        {data.map((d, i) => {
          return (
            <WrapItem key={`w${i}`}>
              <Box
                w="240px"
                h="240px"
                onClick={() => {
                  window.open(d.url);
                }}
                cursor="pointer"
                pos="relative"
              >
                {d.thumbnail ? (
                  <Box
                    h="60%"
                    backgroundColor="black"
                    backgroundImage={d.thumbnail}
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                    opacity={d.feature ? 1 : 0.5}
                  >
                    {/* <Image
                      m="0 auto"
                      h="100%"
                      src={d.thumbnail}
                      objectFit="cover"
                    /> */}
                  </Box>
                ) : (
                  <Box
                    h="60%"
                    backgroundColor="black"
                    opacity={d.feature ? 1 : 0.5}
                  >
                    <Center h="100%">
                      <Text color="white">Image Unavailable</Text>
                    </Center>
                  </Box>
                )}

                {store.userData && store.userData.user.user_type === 'admin' && (
                  <IconButton
                    pos="absolute"
                    top="4px"
                    right="4px"
                    icon={<FaEdit />}
                    colorScheme="blue"
                    zIndex="99"
                    onClick={e => {
                      e.stopPropagation();
                      onOpen();
                      setorder(data[i].priority);
                      setfeatured(data[i].feature);
                      setSelected(data[i]);
                    }}
                  />
                )}

                <Box
                  pos="relative"
                  h="40%"
                  borderLeft="5px solid #FBC02C"
                  p="6px"
                  opacity={d.feature ? 1 : 0.5}
                >
                  <Text lineHeight="18px">{d.title}</Text>
                  <Box pos="absolute" right="8px" bottom="6px">
                    <Flex alignItems="center">
                      <Box textAlign="right" mr="6px">
                        <Text fontSize="14px" fontWeight="bold">
                          {d.author.full_name}
                        </Text>
                        <Text fontSize="12px">
                          {d.author.company ? d.author.company : 'N/A'}
                        </Text>
                      </Box>

                      {d.author && d.author.profile_picture ? (
                        <Image
                          mr="10px"
                          backgroundColor="grey"
                          borderRadius="full"
                          boxSize="30px"
                          src={d.author.profile_picture}
                          alt={store.userData.user.full_name}
                        />
                      ) : (
                        <Circle size="30px" bg="grey" color="black">
                          <Icon as={FaUser} boxSize="18px" circle />
                        </Circle>
                      )}
                    </Flex>
                  </Box>
                </Box>
                {store.userData &&
                  store.userData.user.user_type === 'admin' && (
                    <Text>{`Order: ${d.priority ?? 'Not Set'}`}</Text>
                  )}
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};
